<template>
  <section id="ModeloLean" class="ModeloLean">
    <div class="py-5">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-6 col-lg-6 form wet-form px-5">
            <div class="m-lg-3">
              <h2>
                <span style="color: #14993c"> Español </span>
              </h2>
            </div>
            <input class="mb-5 m-lg-3 fw-bold form-control" v-model="titleES"/>
            <textarea class="mb-5 m-lg-3 form-control" rows="4" v-model="subtitleES"/>
            <button class="btn btn-success btn-lg m-lg-3 mt-3" @click="syncContent('ES')">Guardar</button>
          </div>
          <div class="col-6 text-center text-lg-start">
            <img
              class="img-banner"
              :src="require(`../../assets/img/${imgES}`)"
              alt="Imagen de seccion Modelo lean"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
    <hr class="pill">
    <div class="py-5">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-6 col-lg-6 form wet-form px-5">
            <div class="m-lg-3">
              <h2>
                <span style="color: #14993c"> English </span>
              </h2>
            </div>
            <input class="mb-5 m-lg-3 fw-bold form-control" v-model="titleEN"/>
            <textarea class="mb-5 m-lg-3 form-control" rows="4" v-model="subtitleEN"/>
            <button class="btn btn-success btn-lg m-lg-3 mt-3" @click="syncContent('EN')">Save</button>
          </div>
          <div class="col-6 text-center text-lg-start">
            <img
              class="img-banner"
              :src="require(`../../assets/img/${imgEN}`)"
              alt="Imagen de seccion Modelo lean"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import { ConsultaSeccionBanner8x8 } from "@/helpers/contenido.js";
import { getSection, putSection } from '../../helpers/contenido'
import store from '../../store';
export default {
  name: "ModeloLean",
  data() {
    return {
        titleES: 'El modelo Wetforest 8x8 es de tipo Lean',
        subtitleEs: 'Este es el Framework que aplica a todos los programas ofrecidos.\n' +
          'Los programas son liderados por empresarios y ejecutivos con gran experiencia como strategic advisors.',
        imgES: "esquema_lean_esp.webp",
        titleEN: 'El modelo Wetforest 8x8 es de tipo Lean',
        subtitleEN: 'Este es el Framework que aplica a todos los programas ofrecidos.\n' +
          'Los programas son liderados por empresarios y ejecutivos con gran experiencia como strategic advisors.',
        imgEN: "esquema_lean_esp.webp",
    };
  },
  methods: {
    fetchData() {
        if (store.state.sections.modelLeanBanner) {
      this.data = {...store.state.sections.modelLeanBanner.components};}
    },
    async fetchContent() {
      const section = await getSection("modelLeanBanner");
      const sectionEs = section.filter( (section) => section.language == "ES"  )
      const sectionEn = section.filter( (section) => section.language == "EN"  )

      this.sectionEs = sectionEs
      this.sectionEn = sectionEn

      this.titleES = sectionEs[0].components.title
      this.subtitleES = sectionEs[0].components.subtitle
      this.imgES = sectionEs[0].components.img

      this.titleEN = sectionEn[0].components.title
      this.subtitleEN = sectionEn[0].components.subtitle
      this.imgEN = sectionEn[0].components.img
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const words = {
            id: this.sectionEs[0]._id,
            section: "modelLeanBanner",
            components: {
                title: this.titleES,
                subtitle: this.subtitleES,
                img: this.imgES
            },
            language: "ES"
        }
       await putSection(words);
      } else {
        const words = {
            id: this.sectionEn[0]._id,
            section: "modelLeanBanner",
            components: {
                title: this.titleEN,
                subtitle: this.subtitleEN,
                img: this.imgEN
            },
            language: "EN"

        }
        await putSection(words);
      }
    }
  },

  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },

  mounted() {
    this.fetchData();
    this.fetchContent();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* // Small devices (landscape phones, 576px and up)
 */

.ModeloLean {
  position: relative;

  h2 {
    margin: 0 !important;
    font-size: 2.813rem !important;
  }

  p {
    font-size: 1.25rem !important;
    font-family: InterMedium !important;
  }
}

.background-image {
  position: absolute;
  z-index: -1;
  height: 100%;
}

.img-banner {
  width: 500px;
}

hr.pill {
  height:0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}

@media (max-width: 982px) {
  .background-image {
    opacity: 0.4;
  }

  .img-banner {
    width: 300px;
  }
}
</style>
